//******************************************************************************
  * Toolbar media queries
  ******************************************************************************
// ========== XS (width < 480px)
@media (max-width: $screen-xs-max)
  #header
    padding:
      bottom: 0.6rem
      top: 0.6rem
    .col-branding
      flex-basis: 190px
    .branding
      &__logo
        height: $logo-height-mobile
  .side-wrapper
    .nav-user
      .user-detail
        strong,
        span
          width: 175px!important

  #footer
    flex-direction: column
    padding: (3 * $grid-gutter-width)
    .footer-logo
      margin:
        bottom: 2 * $grid-gutter-width
        top: $grid-gutter-width
      order: 0
      +tal
    .footer-copyright
      text-align: left
      width: auto

  .mini-header
    padding: .315rem 0
    &__section
      &.social-icons
        padding: 0 1rem
        margin: auto
    &__right
      width: 100%

  .nav-language
    .dropdown-menu::before
      right: 7px

// ========== MS (480 - 719px)
@include ms
  #header
    margin: 0
    padding: 0.75rem $padding-screen-ms
    .col-branding
      flex-basis: 190px
    .branding
      img
        width: 120px
        height: 22px
      &__logo
        height: $logo-height-mobile
        video
          height: $nav-height
  .footer-menu
    margin-left: auto
  .footer-logo
    padding-right: 0

  .footer-copyright
    +tal
    +w(auto)

  .mini-header
    padding: .315rem 0
    &__section
      &.social-icons
        padding: 0 1rem
        margin: auto
    &__right
      width: 100%


@media (min-width: $screen-ms-min) and (max-width: 590px)
  .nav-language
    .dropdown-menu::before
      right: calc((100vw - 480px) / 2)

// ========== SM (720 - 959px)
@include sm
  #header
    padding:
      bottom: 1rem
      top: 1rem
    .col-branding
      flex-basis: 200px

  #footer
    padding:
      left: 3 * $grid-gutter-width
      right: 3 * $grid-gutter-width
  .footer-menu
    margin-left: auto
  .footer-logo
    padding-right: 3 * $grid-gutter-width
  .footer-copyright
    +tal
    width: auto
    &__wrap
      margin-left: 0

// ========== MD (960 - 1259px)
@include md
  #header
    .col-branding
      flex-basis: 210px
    .user-detail
      strong, span
        max-width: 195px

//temporarily we do not show logo in portal for non-mobile resolutions
//  so in portal 5th and 6th items are shown
//but for all other projects they are hidden in small resolutions
//todo when portal fully moved to new design remove this and
//  in header_menu.html.twig just show 2 items less
@media (min-width: $screen-md-min) and (max-width: $screen-lg-min)
  #header
    #header-product-list > ul > li
          &:nth-child(5),&:nth-child(6)
              display: none
    &.with-upper-toolbar //this is added by banner systen if #upper_toolbar banner is shown
      #header-product-list > ul > li
            &:nth-child(3),&:nth-child(4)
              display: none

// ========== LG (1260 - 1439px)
@include lg
  #header
    .nav-user
      .user-detail
        strong, span
          max-width: 175px

// ========== XL (1440-1919px)
// @include xl
//

// ========== MQ for touch devices - make buttons and other "touch targets" bigger
@media (pointer: coarse)
  .side-wrapper
    .social-icons
      display: flex
      justify-content: space-around
      a
        i
          font-size: 31px!important
