@font-face {
	font-family: "core-icons";
	font-display: block;
	src: url("../fonts/icons/core-icons.woff2?bd642303d41183f0359db7af63580556") format("woff2");
}

.ico {
	line-height: 1;
}

.ico:before {
	font-family: core-icons !important;
	font-style: normal;
	font-weight: normal !important;
	vertical-align: top;
}

.ipi-album:before {
	content: "\f101";
}
.ipi-alert-info:before {
	content: "\f102";
}
.ipi-alert-warning:before {
	content: "\f103";
}
.ipi-antispam:before {
	content: "\f104";
}
.ipi-antivirus:before {
	content: "\f105";
}
.ipi-app-store:before {
	content: "\f106";
}
.ipi-apple:before {
	content: "\f107";
}
.ipi-arrow-right:before {
	content: "\f108";
}
.ipi-bar:before {
	content: "\f109";
}
.ipi-calendar:before {
	content: "\f10a";
}
.ipi-chevron-down:before {
	content: "\f10b";
}
.ipi-chevron-up:before {
	content: "\f10c";
}
.ipi-close-cross:before {
	content: "\f10d";
}
.ipi-close:before {
	content: "\f10e";
}
.ipi-download:before {
	content: "\f10f";
}
.ipi-envelope:before {
	content: "\f110";
}
.ipi-facebook:before {
	content: "\f111";
}
.ipi-female:before {
	content: "\f112";
}
.ipi-fingerprint:before {
	content: "\f113";
}
.ipi-follow:before {
	content: "\f114";
}
.ipi-galaxy-store:before {
	content: "\f115";
}
.ipi-gear:before {
	content: "\f116";
}
.ipi-google-play:before {
	content: "\f117";
}
.ipi-google:before {
	content: "\f118";
}
.ipi-huawei-store:before {
	content: "\f119";
}
.ipi-incoming:before {
	content: "\f11a";
}
.ipi-language:before {
	content: "\f11b";
}
.ipi-lock:before {
	content: "\f11c";
}
.ipi-magnifi-glass:before {
	content: "\f11d";
}
.ipi-male:before {
	content: "\f11e";
}
.ipi-paper-plane:before {
	content: "\f11f";
}
.ipi-paperclip:before {
	content: "\f120";
}
.ipi-phone:before {
	content: "\f121";
}
.ipi-play:before {
	content: "\f122";
}
.ipi-plus-block:before {
	content: "\f123";
}
.ipi-plus-evelope:before {
	content: "\f124";
}
.ipi-plus-list:before {
	content: "\f125";
}
.ipi-plus-refresh:before {
	content: "\f126";
}
.ipi-plus-simple:before {
	content: "\f127";
}
.ipi-plus-unlimited:before {
	content: "\f128";
}
.ipi-plus:before {
	content: "\f129";
}
.ipi-power:before {
	content: "\f12a";
}
.ipi-right-arrow:before {
	content: "\f12b";
}
.ipi-search:before {
	content: "\f12c";
}
.ipi-share:before {
	content: "\f12d";
}
.ipi-soc-facebook:before {
	content: "\f12e";
}
.ipi-soc-instagram:before {
	content: "\f12f";
}
.ipi-soc-linkedin:before {
	content: "\f130";
}
.ipi-soc-x-twitter:before {
	content: "\f131";
}
.ipi-twitter:before {
	content: "\f132";
}
.ipi-unread:before {
	content: "\f133";
}
.ipi-upload:before {
	content: "\f134";
}
.ipi-user-settings:before {
	content: "\f135";
}
.ipi-user:before {
	content: "\f136";
}
.ipi-xiaomi-store:before {
	content: "\f137";
}
