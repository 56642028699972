
//******************************************************************
  * Product toolbar (bottom/middle of a page)
  ******************************************************************
$more-btn-width: 63px
$product-first-width: 63px

.product-menu
  &__wrap
    height: 2.778rem
    background-color: $brand-dark
    display: flex
    margin: $grid-gutter-width 0
    position: relative

  &__items
    //flex-grow: 1
    font-size: 0.75rem
    //+h($product-list-height)
    +por
    >ul
      display: flex
      flex-flow: row nowrap
      //+lh($product-list-height)
      list-style: none
      margin: 0
      padding: 0
      // Product list is hidden initally
      // JS removes this class when menu is processed
      &.not-ready
        visibility: hidden

      // Items alignment
      &.center
        justify-content: center
        .inx-product
          +tac
      &.left
        justify-content: flex-start
        .inx-product
          flex-grow: initial
      &.right
        justify-content: flex-end
      &.justify
        justify-content: space-evenly
        .inx-product
          flex-grow: 1

    .show
      .dropdown-menu
        display: block
    .inx-product
      border-left: 1px solid $sidebar-border
      color: $white
      cursor: pointer
      flex-grow: 1
      font-size: 0.75rem
      //+oh
      //text-transform: uppercase
      &.product-portal
        border-left: none
        flex-basis: $product-first-width
        flex-grow: 0 !important
      &:hover
        background-color: darken($brand-dark, 5%)
      > a, .dropup > a
        line-height: 2.778rem
      a
        border-top: none
        border-bottom: none
        color: $white
        padding-top: 0
        padding-bottom: 0
        +db
        text-decoration: none
        white-space: nowrap
        font-size: 16px
        .ico-product
          font-size: 1.4rem
        &.without_label
          padding-top: 0 !important
          padding-bottom: 0 !important
          .ico-product
            padding-top: 0 !important
            padding-bottom: 0 !important
            &:before
              font-size: 2.5rem
      .inx-product-dropdown
        width: ($inx-product-width * 2)
        padding: 10px 0
        border: 0

    .product-portal
      +nmp
      +por
      +tac
      +w($product-first-width)
      +w(0, min)
      .ico-product
        margin: 0 !important

  // === "More" button
  &__more
    background-color: $brand-red
    color: $white
    cursor: pointer
    flex-grow: 0
    //+h($product-list-height)
    //+lh($product-list-height)
    padding: 0 10px
    +por
    +w($more-btn-width)
    div
      &:hover
        .ipi-bar
          transform: rotate(90deg)
    div
      +tac
      white-space: nowrap
    .ico.ipi-bar
      color: $white
      +nmp
      @include transition(all 400ms ease)

    &.open
      .ico.ipi-bar
        transform: rotate(90deg)

    .product-menu__hidden-items
      background-color: $brand-dark
      bottom: 50px
      //+db
      padding: 0
      +poa
      right: 0
      +tal
      z-index: 1000
      border: 0

      &.dropdown-menu
        padding-top: 0
        padding-bottom: 0
      li
        border-bottom: 1px solid $sidebar-border
        overflow: hidden
        a
          color: $white
          +db
          text-align: left
          font-size: 0.75rem
          text-decoration: none
          //text-transform: uppercase
          .ico-product
            font-size: 1.4rem
            &.prod-pp,&.prod-xtv
              &:before
                font-size: 2.4rem
                line-height: 1.4rem
    &.go-down
      .product-menu__hidden-items
        bottom: initial

    &.inactive
      visibility: hidden

  // Hidden items menu
  &__hidden-items
    +dn
    margin: 0
    width: 230px
    li
      border-bottom: 1px solid

.product-portal
  .prod-portal
    font-size: 1.4rem
    +por

.product-submenu
  +dn
