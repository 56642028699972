@import "./tb-shared/mixins"
@import "./tb-shared/inx_bootstrap-variables"
@import "./tb/bootstrap-custom"
@import "./tb/core_index"
@import "./tb-shared/modules/inx_bootstrap"
@import "./tb-shared/modules/inx_icon"
@import "./tb-shared/modules/mailplus-promo"

//todo move custom stuff to auto genereted webfonts loader
.ico-product,
.ico,
[class^="ifi-"]:before,
[class*=" ifi-"]:before
  display: inline-block
  vertical-align: middle
  //line-height: 1
  font-weight: normal
  font-style: normal
  speak: none
  text-decoration: inherit
  text-transform: none
  text-rendering: auto
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale

[class^="ifi-"]:before,
[class*=" ifi-"]:before
  font-family: shared-icons !important
  font-style: normal
  font-weight: normal !important
  //vertical-align: middle

