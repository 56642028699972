@import "./core_colors";

$core-border-radius: 0;

$modal-backdrop-opacity:        1;

$zindex-dropdown:               1001;

//Buttons
//todo btn-default was renamed to btn-secondary in bootstrap4
$btn-default-color:             $white;
$btn-default-bg:                $brand-dark;
$btn-default-border:            $brand-dark;

$btn-primary-bg:                $brand-red;
$btn-primary-border:            $brand-red;
$padding-base-vertical:         0.5rem;
$padding-base-horizontal:       0.75rem;

$opacity-on-hover:              1;
$opacity-default:               0.8;
$opacity-not-active:            0.5;

/*
Extra breakpoints and changing default ones.
XS  300+    (BS default x<750)
MS  480+    (extra breakpoint)
SM  720+    (BS default 750+)
MD  960+    (BS default 970+)
LG  1260+   (BS default 1200+)
XL  1440+   (extra breakpoint)
XXL 1920+   (extra breakpoint)
*/
//Heights & widths
$grid-gutter-width:             10px;
$grid-gutter-height:            $grid-gutter-width;
$nav-height-max:                100px;
$nav-height-min:                55px;
$nav-height:                    36px;
$footer-height:                 250px;
$dropdown-width:                138px;
$widget-header:                 40px;
$login-input-height:            140px;
$product-list-height:           50px;
$main-block-height:             300px;
$inx-product-more-md:           76px;
$inx-product-more-lg:           92px;
$inx-product-more-xl:           60px;
$inx-product-more-xxl:          72px;
$inx-product-width:             136px;
$inx-product-width-xl:         130px;
$inx-product-width-xxl:         136px;
$sidemenu-width:                300px;
$padding-screen-ms:             30px;
$modal-inner-padding:           0.875rem;
$modal-md:                      50%;
$input-height-base:             ($line-height-computed + ($padding-base-vertical * 2));
$form-group-margin-bottom:      1.4rem;

$logo-height-default: 50px;
$logo-height-mobile: 40px;

//todo these param look like portal specific? should be moved there
$col-adv:                       400px;
$col-adv-xl:                    540px;
$col-adv-height:                610px;
$col-login-md:                  540px;
$col-login-lg:                  430px;
$col-login-xl:                  480px;
$col-login-xxl:                 490px;

//XS - Extra small screen / phone
$screen-xs:                     300px;
$screen-xs-min:                 $screen-xs;
$screen-phone:                  $screen-xs-min;
$container-xs:                  100%;

$screen-sm:                     720px;
$screen-sm-min:                 $screen-sm;


//MS - Mid-Small breakpoint
$screen-ms:                     480px;
$screen-ms-min:                 $screen-ms;
$screen-ms-max:                 ($screen-sm-min - 1);
$container-ms:                  (470px + $grid-gutter-width);

//SM - Small screen / tablet
$screen-tablet:                 $screen-sm-min;
$container-tablet:              (710px);
$container-sm:                  $container-tablet;

//MD - Medium screen / desktop
$screen-md:                     960px;
$screen-md-min:                 $screen-md;
$screen-desktop:                $screen-md-min;
$container-desktop:             (950px);
$container-md:                  $container-desktop;

//LG - Large screen / wide desktop
$screen-lg:                     1260px;
$screen-lg-min:                 $screen-lg;
$screen-lg-desktop:             $screen-lg-min;
$container-large-desktop:       (1250px);
$container-lg:                  $container-large-desktop;

//XL - Extra large screen
$screen-xl:                     1440px;
$screen-xl-min:                 $screen-xl;
$screen-xl-desktop:             $screen-xl-min;
$container-xlarge-desktop:      (1430px);
$container-xl:                  $container-xlarge-desktop;

//XXL - Extra-extra large screen
$screen-xxl:                    1920px;
$screen-xxl-min:                $screen-xxl;
$screen-xxl-desktop:            $screen-xxl-min;
$container-xxlarge-desktop:     (1910px);
$container-xxl:                 $container-xxlarge-desktop;

// Redefined max value
$screen-xs-max-new:             ($screen-ms-min - 1);
$screen-xs-max:                 $screen-xs-max-new;
$screen-ms-max:                 ($screen-sm-min - 1);
$screen-sm-max:                 ($screen-md-min - 1);
$screen-md-max:                 ($screen-lg-min - 1);
$screen-lg-max:                 ($screen-xl-min - 1);
$screen-xl-max:                 ($screen-xxl-min - 1);

// BS variable override
$theme-colors: (
        'brand-red': $brand-red,
        'brand-dark': $brand-dark,
        'brand-orange': $brand-orange,
        'brand-gray': $brand-gray,
        'brand-blue': $brand-blue,

        "primary": $brand-primary,
        "default": $brand-dark,
        "secondary": $brand-dark,
        "success": $brand-success,
        "info": $brand-info,
        "warning": $brand-warning,
        "danger": $brand-danger,
        "light": $brand-light,

        "brand-facebook": $facebook-color,
        "brand-twitter": $twitter-color,
        "brand-google": $google-color,

        //"sidebar-mail-plus": $sidebar-mail-plus,
        //"sidebar-files-plus": $sidebar-files-plus,
) !default;

$border-radius: $core-border-radius;

/*
XS  300+    (BS default x<750>x)
MS  480+    (extra breakpoint)
SM  720+    (BS default 750+)
MD  960+    (BS default 970+)
LG  1260+   (BS default 1200+)
XL  1440+   (extra breakpoint)
XXL 1920+   (extra breakpoint)
*/

$grid-breakpoints: (
        xs: 0,
        ms: 480px,
        sm: 720px,
        md: 960px,
        lg: 1260px,
        xl: 1440px,
        xxl: 1920px
) !default;

$container-max-widths: (
        ms: 480px,
        md: 950px,
        lg: 1250px,
        xl: 1430px,
        xxl: 1910px
) !default;
