@font-face {
	font-family: "shared-icons";
	font-display: block;
	src: url("../fonts/icons/shared-icons.woff2?d13ad724a51838db7e236cfd544656a3") format("woff2");
}

.icon {
	line-height: 1;
}

.icon:before {
	font-family: shared-icons !important;
	font-style: normal;
	font-weight: normal !important;
	vertical-align: top;
}

.ifi-angle-double-left:before {
	content: "\f101";
}
.ifi-angle-double-right:before {
	content: "\f102";
}
.ifi-arrow-curved-back:before {
	content: "\f103";
}
.ifi-arrow-down:before {
	content: "\f104";
}
.ifi-arrow-up:before {
	content: "\f105";
}
.ifi-attach-download-zip:before {
	content: "\f106";
}
.ifi-attach-download:before {
	content: "\f107";
}
.ifi-calendar:before {
	content: "\f108";
}
.ifi-check:before {
	content: "\f109";
}
.ifi-close-thick:before {
	content: "\f10a";
}
.ifi-contacts:before {
	content: "\f10b";
}
.ifi-download:before {
	content: "\f10c";
}
.ifi-envelope-close:before {
	content: "\f10d";
}
.ifi-envelope-open:before {
	content: "\f10e";
}
.ifi-eye-close:before {
	content: "\f10f";
}
.ifi-eye-open:before {
	content: "\f110";
}
.ifi-filter:before {
	content: "\f111";
}
.ifi-folder-plus:before {
	content: "\f112";
}
.ifi-home:before {
	content: "\f113";
}
.ifi-list:before {
	content: "\f114";
}
.ifi-logo-files:before {
	content: "\f115";
}
.ifi-logo-foto:before {
	content: "\f116";
}
.ifi-logo-id:before {
	content: "\f117";
}
.ifi-minus:before {
	content: "\f118";
}
.ifi-mobile:before {
	content: "\f119";
}
.ifi-paperclip:before {
	content: "\f11a";
}
.ifi-pause:before {
	content: "\f11b";
}
.ifi-pencil:before {
	content: "\f11c";
}
.ifi-play:before {
	content: "\f11d";
}
.ifi-plus:before {
	content: "\f11e";
}
.ifi-profile:before {
	content: "\f11f";
}
.ifi-reload:before {
	content: "\f120";
}
.ifi-rotate-left:before {
	content: "\f121";
}
.ifi-rotate-right:before {
	content: "\f122";
}
.ifi-search:before {
	content: "\f123";
}
.ifi-share-twitter:before {
	content: "\f124";
}
.ifi-sign-danger:before {
	content: "\f125";
}
.ifi-sign-info:before {
	content: "\f126";
}
.ifi-sign-question:before {
	content: "\f127";
}
.ifi-sign-success:before {
	content: "\f128";
}
.ifi-sign-warning:before {
	content: "\f129";
}
.ifi-stop:before {
	content: "\f12a";
}
.ifi-th-large:before {
	content: "\f12b";
}
.ifi-th-list:before {
	content: "\f12c";
}
.ifi-to-end:before {
	content: "\f12d";
}
.ifi-to-start:before {
	content: "\f12e";
}
.ifi-trash:before {
	content: "\f12f";
}
.ifi-triangle-down:before {
	content: "\f130";
}
.ifi-triangle-left:before {
	content: "\f131";
}
.ifi-triangle-right:before {
	content: "\f132";
}
.ifi-triangle-up:before {
	content: "\f133";
}
.ifi-upload-classic:before {
	content: "\f134";
}
