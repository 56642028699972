$avatar-height: 36px
$avatar-width: 36px
$header-menu-btn-color: rgba($brand-gray, 0.7)

#header
  font-size: 1rem
  align-items: center
  display: flex
  justify-content: space-between
  // NOTE: To prevent unnecessary horizontal scroll bar.
  // Absolute value of the left and right margin must be equal
  // to the left and right padding of the parent '.container-fluid' element
  margin-left: -5px
  margin-right: -5px
  padding-left: 5px
  padding-right: 5px
  //padding: 1.25rem 5px
  position: relative
  transform: translateZ(0) // Fix for ios safari, add hardware acceleration
  transition: transform 600ms
  z-index: 1000

  .input-group-btn
    button
      outline: none
      &:hover
        background-color: darken($brand-dark, 10%)

  .btn-checkmail
    padding:
      left: $grid-gutter-width
      right: $grid-gutter-width

  #header-product-menu
    min-width: 0 //to not overflow #header wrapper https://stackoverflow.com/questions/36230944/prevent-flex-items-from-overflowing-a-container

  #header-product-list
    ul
      list-style-type: none
      li
        a
          text-wrap: nowrap

  .header-product-more-list
    display: grid
    grid-template-columns: repeat(2, minmax(0, 1fr))
    grid-gap: 5px
    min-width: 328px
    .dropdown-item
      .btn
        height: auto
        background-color: transparent
        border: none
        .ico-product
          //font-size: 1.4rem
          &.prod-pp,&.prod-xtv
            &:before
              top: 2px
              line-height: 1.2rem
      &:hover
        background-color: transparent
  .header-products-more-toggle
    .ico
      line-height: 0.8
    .ipi-chevron-down
      display: inline
    .ipi-chevron-up
      display: none
    &.show
      .ipi-chevron-up
        display: inline
      .ipi-chevron-down
        display: none
  .dropdown-menu
    box-shadow: 0px 0px 32px 0px rgba(0, 0, 0, 0.18)

.main-header
  .btn
    line-height: 1.1
    &.active:not(#side-trigger,.btn-brand-red),&:hover:not(#side-trigger,.btn-brand-red),&.show:not(#side-trigger,.btn-brand-red)
      background-color: $header-menu-btn-color
      border-color: $header-menu-btn-color

    &.btn_i3x-side-menu
      margin-left: 0.8em
      padding: .4em
      border-radius: 0.125rem

  .ipi-bar
    color: currentColor !important
    font-size: 1.3em
    display: inline-block
    transition: initial !important
    &:hover
      transform: none !important

// === Header-navigation
.col-branding
  flex-basis: 210px

.branding
  white-space: nowrap
  &__logo
    display: block
    height: $logo-height-default
    width: 100%
    position: relative
    top: 2px
    background-position: 0 50%
    background-size: contain
    background-repeat: no-repeat
    margin-right: 10px

.nav-logout
  flex-shrink: 0
  &.btn-group
    .btn+.btn
      margin-left: 1px

  .btn-home
    padding:
      left:  $grid-gutter-width
      right: $grid-gutter-width
  .ico-product
    font-size: $font-size-base + 4
    margin: 0

.col-profile
  display: inline-flex
  align-items: center
  justify-content: space-between
  text-align: right
  white-space: nowrap

.nav-user
  //padding: 0 .5em 0 .8em
  //.rounded-circle
  //  border-radius: 50%
  .user-img,
  .user-detail
    //display: inline-block
    //vertical-align: middle
    strong,
    span
      +db
      text-align: left
      color: $dark-gray
      +h(16px)
      +lh(14px)
      max-width: 10vw
      overflow: hidden
      text-overflow: ellipsis
      white-space: nowrap
    a:hover
      text-decoration: none
    strong
      color: $black
    img, .user-initials
      height: $avatar-height
      width: $avatar-width
    .user-initials
      align-items: center
      background-color: $brand-gray
      display: inline-flex
      font-size: 0.875rem
      font-weight: 900
      justify-content: center
      text-transform: uppercase
