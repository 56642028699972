//******************************************************************************
//* Language switcher
//******************************************************************************
.nav-language
  .lang-label
    min-width: 60px

  .dropdown-menu
    min-width: 107px
    >li.dropdown-item
      color: $black
      cursor: pointer
      font-size: 14px
      &:hover
        color: $white
