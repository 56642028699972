@font-face {
	font-family: "product-icons";
	font-display: block;
	src: url("../fonts/icons/product-icons.woff2?166a64e931467a104b27ef4baddbf180") format("woff2");
}

.ico-product {
	line-height: 1;
}

.ico-product:before {
	font-family: product-icons !important;
	font-style: normal;
	font-weight: normal !important;
	vertical-align: top;
}

.prod-amigos:before {
	content: "\f101";
}
.prod-business_eu:before {
	content: "\f102";
}
.prod-calendar:before {
	content: "\f103";
}
.prod-contacts:before {
	content: "\f104";
}
.prod-dating:before {
	content: "\f105";
}
.prod-files:before {
	content: "\f106";
}
.prod-foto:before {
	content: "\f107";
}
.prod-games:before {
	content: "\f108";
}
.prod-go:before {
	content: "\f109";
}
.prod-help:before {
	content: "\f10a";
}
.prod-home:before {
	content: "\f10b";
}
.prod-inx:before {
	content: "\f10c";
}
.prod-lindoamor:before {
	content: "\f10d";
}
.prod-mail_plus:before {
	content: "\f10e";
}
.prod-mail:before {
	content: "\f10f";
}
.prod-news:before {
	content: "\f110";
}
.prod-portal:before {
	content: "\f111";
}
.prod-pp:before {
	content: "\f112";
}
.prod-settings:before {
	content: "\f113";
}
.prod-xtv:before {
	content: "\f114";
}
