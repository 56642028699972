//******************************************************************************
  * FOOTER
  ******************************************************************************
#footer
  background-color: $brand-dark
  bottom: 0
  color: $white
  min-height: $footer-height
  min-width: 300px
  font-size: 0.75rem
  //+fsz(1.3rem)
  //+lh(1.6rem)
  +lh(1rem)
  position: absolute
  width: 100%
  flex-shrink: 0
  .container
    background-color: transparent !important
  .footer-wrapper
    display: flex
    flex-wrap: wrap
    justify-content: flex-start
    padding: (3 * $grid-gutter-width) (4 * $grid-gutter-width)

.footer-logo
  order: -1
  padding-right: 4 * $grid-gutter-width
  +tac
  img
    // +w(170px)
    +h(31px)
    &[src*="bizmail_white"], &[src*="ee_white"]
      +h(45px)
    &[src*="white_QA"], &[src*="white_VG"], &[src*="white_PK"]
      +h(40px)

.footer-menu
  ul
    list-style-type: none
    padding: 0
  a
    color: $white
    text-decoration: underline
    font-weight: 100
    line-height: 1.4em
    &:hover
      color: $white
      opacity: $opacity-default

.footer-copyright
  &__wrap
    margin-left: auto
    // max-width: 40vw
  font-weight: 100
  +tar
  +w(350px)
