/* Import original Bootstrap variables */
@import "bootstrap/scss/variables"
/* Import original Bootstrap mixins */
@import "bootstrap/scss/mixins"
@import '~src/sass/tb/core_variables'

/* Vertical centering. Credits to: https://codepen.io/dimbslmh/full/mKfCc */

$color-base: #8B8179

.promo-modal, .new-promo-modal
  padding: 0 !important
  text-align: center

  .modal-header, .header
    right: auto !important
    top: auto !important
    transform: none !important
    width: auto !important
    border: hidden
    padding: initial

  .btn-close
    position: absolute
    top: 15px
    right: 15px
    font-size: 1rem
    width: 1.5rem
    height: 1.5rem
    opacity: 0.6 !important
    &:hover
      opacity: 1

  .modal-logo
    cursor: pointer
    width: 60%

  .modal-dialog
    height: auto
    margin: 10px auto !important
    max-width: 500px

  .modal-content
    overflow: visible !important
    padding: (3 * $grid-gutter-width) !important
    top: auto !important
    position: relative !important

  .modal-body
    padding: 10px !important

    p
      font-size: $font-size-h4
      margin: $grid-gutter-width 0 !important

  .modal-footer
    display: flex
    flex-direction: column

  &_center-vertical
    &:before
      content: ''
      display: inline-block
      height: 100%
      margin-right: -4px
      vertical-align: middle

    .close
      right: - #{$grid-gutter-width * 3 + 5}

    .modal-dialog
      display: inline-block
      vertical-align: middle

    .modal-logo
      width: 50%

// Specific styles for the original promo modal
.promo-modal
  .modal-content
    padding: (2 * $grid-gutter-width) (3 * $grid-gutter-width) (1 * $grid-gutter-width) (3 * $grid-gutter-width) !important

  .header
    padding: 0 0 (2 * $grid-gutter-width) 0

  .modal-btn
    background-color: transparent
    border: 0
    radius: 0
    margin-top: $grid-gutter-width * 0.5
    padding: $grid-gutter-width 0
    text-transform: uppercase
    justify-content: normal
    display: inline
    &:focus
      outline: none

    &_default
      width: 100%
      border: 1px solid #8B8179
      background-color: transparent

    &_link
      width: 100%
      text-transform: none
      font-size: 0.9rem
      height: 3 * $grid-gutter-width


// ========== Themes ==========
.promo-modal
  // Default theme
  li
    padding-bottom: 4px
    list-style: disc
    &:last-child
      padding-bottom: 0
    &::marker
      color: $brand-red
      font-size: 24px
  &_theme-default
    color: $color-base
    .modal-header
      border-bottom: 1px solid lighten($color-base, 30%)
    .modal-footer
      border-top: 1px solid lighten($color-base, 30%) !important
    .modal-btn
      color: $color-base
      &_default
        border: 1px solid #8B8179 !important
        background-color: transparent !important
      &_primary
        background-color: $brand-red !important
        color: #fff !important

  // Red theme
  &_theme-red
    .modal-header
      background-color: transparent !important
      border-bottom: 1px solid #fff !important
    .modal-content
      color: white
      background-color: $brand-red
    .modal-footer
      border-top: 1px solid #fff
    .modal-btn
      color: #fff
      &_default
        border: 1px solid #fff
      &_primary
        background-color: #fff
        color: $brand-red

// Specific styles for the new promo modal
.new-promo-modal
  .modal-content
    width: 39.3rem
    height: 31.5rem
    flex-shrink: 0
    background-image: url('../img/premium_pop-up_background.svg')
  .header
    padding: 0 !important

  .modal-body
    display: flex
    flex-direction: column
    align-items: flex-start
    gap: 4.5rem
    align-self: stretch

    p
      color: #FFF
      font-family: Inter
      font-size: 2rem
      font-weight: 200
      line-height: normal

    span
      font-weight: 700

    .feature-list
      display: flex
      flex-direction: column
      align-items: flex-start

      p
        color: #000
        font-family: Inter
        font-size: 1.125rem
        font-weight: 500
        line-height: 1.39225rem
        display: flex
        align-items: center
        gap: 0.5rem

      p::before
        content: url('../img/FiCheckCircle.svg')
        width: 1.5rem
        height: 1.5rem

  .modal-footer
    border: hidden !important
    align-items: flex-start
    gap: 0.625rem
    align-self: stretch

    .modal-button_primary
      padding: 0.75rem 1.5rem
      border-radius: 0.5rem
      background: #CE2127
      color: #FFF
      text-align: center
      font-family: Inter
      font-size: 1.125rem
      font-weight: 600
      line-height: 1.3125rem
      text-transform: uppercase

    .modal-button_default
      padding: 0.75rem 1.5rem
      border-radius: 0.5rem
      color: var(--dark-mode-system-grey-grey-2, #636366)
      border: 1px solid var(--dark-mode-system-grey-grey-2, #636366)
      text-align: center
      font-family: Inter
      font-size: 1.125rem
      font-weight: 400
      line-height: 1.3125rem

// Theme styles for new promo modal
.new-promo-modal_theme-default
  li
    padding-bottom: 4px
    list-style: disc

    &:last-child
      padding-bottom: 0

    &::marker
      color: $brand-red
      font-size: 24px
@media (max-width: $screen-md)
  .new-promo-modal
    .modal-dialog
      max-width: 80%

@media (max-width: $screen-sm)
  .new-promo-modal
    .modal-dialog
      max-width: 80%
      margin: 20px auto !important

    .modal-content
      padding: $grid-gutter-width !important
      width: 100%
      height: auto
      background-size: cover
      background-position: 80% center

    .modal-logo
      width: 50%

    .modal-body
      padding: $grid-gutter-width !important

      p
        font-size: $font-size-base !important
        margin: $grid-gutter-width 0 0 0 !important

    .modal-footer
      flex-direction: column
      gap: $grid-gutter-width !important

    .modal-btn
      width: 100%
      padding: $grid-gutter-width 0 !important
      font-size: $font-size-base

    .header
      padding-bottom: $grid-gutter-width !important

    .modal-content
      .modal-button_primary, .modal-button_default
        width: 100%
        font-size: $font-size-base
        padding: 0.5rem

    .modal-body
      p
        font-size: $font-size-h5

      .feature-list p
        font-size: 0.8rem !important

    .modal-footer
      gap: $grid-gutter-width !important

@media (max-width: $screen-ms)
  .new-promo-modal
    .modal-body
      .feature-list p
        font-size: 9px !important
