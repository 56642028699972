//******************************************************************************
  * Description: Defaults for all products
  ******************************************************************************
// TODO: Delete when removed in products
  // Images
  // $com-img: '/' + $com-path + '/img/'
  // Common images
  // $ico-ui-img: $com-img + 'jquery-ui/ui-icons_454545_256x240.png'
  // Local images
  // $pref-logo-img: '../img/logo/'

// Default site width
$site-width-default: 980px !default

// Default border radius for compass
//todo remove on BS5
$default-border-radius: $border-radius-base

// Explanation: $default-site-width / 140 = 7
$grid-min-size: .4375rem !default

// Logos
$ii-logo-height: 51px !default
// Logo width is variable, so can set it
//$ii-logo-width: 173px !default

$ii-logo1-height: 26px !default
$ii-logo1-width: 173px !default

// Quota bar
$ii-quota-bar-height: 26px !default

// Pager
$ii-pager-height: 22px !default
$pager-button-height: 22px !default
$pager-button-width: 22px !default

// jQuery tooltip
$tooltip-body-bg: #ffffcf !default

// Inbox icon sizes
// 16 x 16
$ico-height: 16px !default
$ico-width: 16px !default
// 24x24
$ico1-height: 24px !default
$ico1-width: 24px !default
// 32x32
$ico2-height: 32px !default
$ico2-width: 32px !default

// Compass
$disable-magic-sprite-selectors: true
