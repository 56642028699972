//******************************************************************************
  * Inbox quirks for the bootstrap alerts
  ******************************************************************************
@import '../inx_variables'
@import '../mixins'

//todo not good as auto-genereted icons can change ids for each icon
%alert-icons
  $alert-list: ((danger, "\f125", $brand-danger), (info, "\f126", $link-color), (success, "\f128", $brand-success), (warning, "\f129", $brand-warning))
  @each $itm in $alert-list
    &.alert-#{nth($itm, 1)}
      &:before
        @if length($itm) > 2
          color: nth($itm, 3)
        content: nth($itm, 2)

.alert
  margin-bottom: $grid-min-size
  // Have to use <a> tag, because class name might be missing
  a, .alert-link
    &:hover
      +tdu

  // Alert as inline block
  &_inline
    +dib
    margin:
      bottom: 0
      top: 0
    padding:
      bottom: calc($grid-min-size - 2px)
      top: calc($grid-min-size - 1px)
    +h(4 * $grid-min-size + 2, min)
    min-height: 0
    &:before
      left: $grid-min-size - 1
      top: $grid-min-size - 1

  // Alert with icon
  &.alert_has-icon
    padding-left: 28px
    +por
    +tal
    &:before
      display: inline
      content: ''
      font:
        size: 1.1rem
        family: shared-icons
      +h($ico-height)
      left: -(2 * $grid-gutter-width)
      margin-right: -(2 * $grid-gutter-width - 4)
      +por
      top: 50%
      transform: perspective(1px) translateY(-50%)
      +w($ico-width)

    @extend %alert-icons
