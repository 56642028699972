@import "../tb-blocks/header"
@import "../tb-blocks/language-switcher"
@import "../tb-blocks/products-bar"
@import "../tb-blocks/footer"
@import "../tb-media-queries/mq_index"
@import "../tb-blocks/side-menu_linked-accounts"

a
  color: $brand-dark
  cursor: pointer
  &:hover
    color: $brand-red

.inline-block
  display: inline-block
  vertical-align: middle

// Icons
.ico
  font-size: 0.875rem
  &.ipi-bar
    color: $brand-red
    cursor: pointer
    background: none
    border: none
    outline: none
    @include transition(all 400ms ease)
    &:hover
      transform: rotate(90deg)

.ico-product
  font-size: 24px
  margin: 0 5px 0 12px
  &:before
    position: relative
    top: -2px



//todo simplify and remove badge code

.badge-indicator
  background-color: $brand-red
  border-radius: 50%
  display: none
  height: .9em
  width: .9em
  padding: 0
  &.on
    display: block
  &.top-right
    position: absolute
    right: -3px
    top: -3px
  &.show-count
    padding: 0.5rem
    width: auto
    right: -6px
    top: -6px
    border-radius: 40%
    .badge-link
      position: relative
      top: -6px

//Sidebar navigation
#sidebar
  display: flex
  flex-direction: column
  .ico.ipi-bar
    color: $white
    transform: rotate(90deg)
    margin-top: 0

body.side-open,
.modal-open
  overflow: hidden !important

.side-open .headerContainerWrapper,
.side-open .bodyContainerWrapper,
.side-open .footerContainerWrapper
  left: -300px

.open-sidebar
  padding: 30px

.close-sidebar
  font-weight: bold
  text-decoration: none
  color: $black
  display: inline-block
  margin-top: -15px
  margin-bottom: 5px

.ico-product
  font-size: 2.5rem
  margin: 0
  &.small-ico
    font-size: 1.4rem
  &.prod-pp,&.prod-xtv
    &:before
      font-size: 3.5rem
      line-height: 2.5rem

#side-menu
  --toolbar-font-size-base: 16px
  //todo remove when we remove Proxima Nova from mail product
  font-family: system-ui, -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Ubuntu, roboto, noto, arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"
  .menu-label
    color: $gray-text
    font-size: 0.75rem
  width: $sidemenu-width
  background: $brand-dark
  color: $white
  #side-nav
    li
      overflow: hidden
      min-width: 0
  i
    margin-right: .5em
  .login-buttons
    a
      padding: 0.25rem 0
  .links-100 a
    width: 100%
  a
    color: $white
  @include transition(all 400ms ease)
  .side-menu-close
    font-size: 30px
    height: 50px
    left: -56px
    margin: 0
    position: absolute
    top: 1px
    width: 50px
    display: flex
    justify-content: center
    align-items: center
    &:before
      color: $white
      font-size: 16px
  .btn
    transition: none !important
    -webkit-transition: none !important
    &.login-btn
      background: rgba(255, 255, 255, 0.1)
      &:hover
        background: rgba(0, 0, 0, 0.1)

  .side-list
    list-style-type: none
    padding: 0
    a
      font-size: 0.875rem
    .ico
      font-size: 1.5rem
    &.product-list
      display: grid
      grid-template-columns: repeat(3, 1fr)
      grid-gap: 0.5rem 1rem
      li
        a
          display: flex
          flex-direction: column
          border-radius: var(--bs-border-radius-lg)
          transition: border 0.3s ease-in-out
          font-size: 0.875rem
          color: $brand-gray
          --bs-btn-padding-x: 0
          .ico-product
            font-size: 2.5rem
            margin: 0
            &.prod-pp,&.prod-xtv
              &:before
                top: 15px
                position: absolute
                left: 7px

          &.active,&:hover
            background-color: transparent
            border-color: $input-border
            color: $white

  .side-wrapper
    width: $sidemenu-width
    .side-footer
      li
        text-transform: none
        a
          font-size: 14px
    .nav-user
      font-size: 0.875rem
      background: rgba(255, 255, 255, 0.16)
      //min-height: 60px
      //display: flex
      align-items: center
      border-bottom: 1px solid $sidebar-border
      .premium-badge
        font-size: calc(var(--toolbar-font-size-base) * 0.625)
        line-height: calc(var(--toolbar-font-size-base) * 0.925)
        letter-spacing: 0.1em
        font-weight: 500
      .user-detail
        //width: 209px
        margin-left: .5rem
        strong
          color: $white
          max-width: inherit
          width: 195px
        span
          color: $gray-text
          max-width: inherit
          width: 195px

    .app-stores
      a
        flex: 1 1 auto !important

    .social-icons a, .app-stores a
      &:hover
        opacity: .8
      i
        font-size: 1.8rem

    .social-icons
      a
        display: inline-flex
        border-radius: 0.25rem
        background: rgba(255, 255, 255, 0.10)
        margin-right: 1rem
        i
          font-size: 2rem
          margin-right: 0

.side-open #side-menu
  right: 0
  opacity: 1

.ml-auto
  margin-left: auto!important
